import React from 'react';
import {Helmet} from 'react-helmet';

export default function OgHeader(props) {
  const {landing} = props;
  return (
    <Helmet>
      <title>{landing.custom_layout.hishop_og_title || 'Title'}</title>
      <meta
        property="og:site_name"
        content={landing.custom_layout.hishop_og_title || 'Title'}
      />
      <meta
        property="og:title"
        content={landing.custom_layout.hishop_og_title || 'Title'}
      />
      <meta
        property="og:image"
        content={landing.custom_layout.hishop_og_image}
      />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      <meta
        property="og:description"
        content={landing.custom_layout.hishop_og_description || 'Description'}
      />

      <meta
        name="title"
        content={landing.custom_layout.hishop_og_title || 'Title'}
      />
      <meta
        name="description"
        content={landing.custom_layout.hishop_og_description || 'description'}
      />
      <meta name="image" content={landing.custom_layout.hishop_og_image} />
    </Helmet>
  );
}
