import React from 'react';
import styled from 'styled-components';

export default function MktNavbar(props) {
  const {solid = false, scrollToCta, ctaText} = props;
  return (
    <Wrapper solid={solid}>
      <div className="content">
        <div className="title">
          <img src="/images/hishop-nav-logo.png" alt="hishop" />
        </div>
        <div style={{flex: 1}} />
        <button className="btn" onClick={scrollToCta}>
          {ctaText}
        </button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  z-index: 1000;
  background-color: ${(props) => (props.solid ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.solid ? '0 2px 4px rgba(0,0,0,0.2)' : 'none'};
  transition: all 300ms;

  & > .content {
    flex: 1;
    max-width: 1160px;
    margin: 0px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;

    & > .title {
      & > img {
        width: 140px;
        height: 28px;
        object-fit: contain;
      }
    }

    & > .btn {
      background-color: white;
    }
  }
`;
