import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import ReactDelighters from 'rev.sdk.js/Components/ReactDelighters';
import OgHeader from './OgHeader';
import MktNavbar from './MktNavbar';
import ScreenshotCarousel from './ScreenshotCarousel';
import ProductGrid from '../ProductList/ProductGrid';
import useScrollBreakpoint from '../../Hooks/useBreakPoint';

export default function MktLanding(props) {
  return (
    <ReactDelighters>
      <MktLandingImpl {...props} />
    </ReactDelighters>
  );
}

function MktLandingImpl(props) {
  const {passBreakpoint} = useScrollBreakpoint(200);
  const [_landing] = useOutlet('landing');
  const {
    pageContext: {prebuiltSiteConfig},
  } = props;
  const [articles, setArticles] = React.useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const landing = _landing || prebuiltSiteConfig.landing || {};

  function scrollToCta() {
    const section = document.querySelector('#cta');
    if (section) {
      window.scrollTo({top: section.offsetTop, behavior: 'smooth'});
    }
  }

  React.useEffect(() => {
    async function fetchArticles() {
      let articleIds = landing.custom_layout.hishop_articles_items || [];
      let articles = [];

      let _articles = await JStorage.fetchDocuments(
        'Article_Default',
        {
          _id: {
            $in: [
              ...articleIds.map((id) => ({
                $oid: id,
              })),
            ],
          },
        },
        null,
        null,
        null,
        {anonymous: true},
      );

      for (let id of articleIds) {
        let p = _articles.find((p) => p.id === id);
        if (p) {
          articles.push(p);
        }
      }

      setArticles(articles);
    }

    fetchArticles();
  }, [landing]);

  return (
    <Wrapper>
      <OgHeader landing={landing} />

      <MktNavbar
        solid={passBreakpoint}
        scrollToCta={scrollToCta}
        ctaText={landing.custom_layout.hishop_nav_btn_text || 'GO'}
      />

      <HeroBanner bgImage={landing.custom_layout.hishop_hero_bg_image}>
        <div className="content">
          <div className="text">
            <h2 className="title">
              {landing.custom_layout.hishop_hero_title || 'Title'}
            </h2>
            <div className="subtitle">
              {landing.custom_layout.hishop_hero_subtitle || 'Subtitle'}
            </div>
            <button className="btn" onClick={scrollToCta}>
              {landing.custom_layout.hishop_hero_btn_text || 'Action'}
            </button>
          </div>

          {landing.custom_layout.hishop_hero_fg_image && (
            <div className="image">
              <img
                src={landing.custom_layout.hishop_hero_fg_image}
                alt="logo"
              />
            </div>
          )}
        </div>
      </HeroBanner>

      <Customers>
        <div className="content">
          <h2 className="title" data-delighter="start:0.8;">
            {landing.custom_layout.hishop_customers_title || 'Customers'}
          </h2>
          <div className="items">
            {(landing.custom_layout.hishop_customers_items || []).map(
              (item, idx) => {
                return (
                  <div key={idx}>
                    <figure>
                      <img src={item.image} alt="logo" />
                      <div className="on-hover">
                        <a
                          className="btn"
                          href={item.url}
                          target="_blank"
                          rel="noreferrer">
                          查看網站
                        </a>
                      </div>
                    </figure>
                    <div className="title">{item.title}</div>
                    <div className="text">{item.text}</div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </Customers>

      <Features>
        <div className="content">
          <h2 className="title">
            {landing.custom_layout.hishop_features_title || 'Features'}
          </h2>
          <div className="items">
            {(landing.custom_layout.hishop_features_items || []).map(
              (item, idx) => {
                return (
                  <div key={idx} data-delighter="start:0.8;">
                    <img
                      src={item.image || '/images/revicon_512.png'}
                      alt="logo"
                    />
                    <div className="content">
                      <div className="title">
                        <div className="idx">{idx + 1}</div>
                        <div>{item.title}</div>
                      </div>
                      <div className="description">{item.description}</div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
      </Features>

      <Screenshots>
        <h2 className="title">
          {landing.custom_layout.hishop_screenshots_title || 'Screenshots'}
        </h2>
        <div className="content">
          <ScreenshotCarousel
            items={landing.custom_layout.hishop_screenshots_items || []}
          />
        </div>
      </Screenshots>

      <Articles>
        <h2 className="title">
          {landing.custom_layout.hishop_articles_title || 'Learn More'}
        </h2>
        <div className="product-list">
          <ProductGrid
            products={articles}
            prefixPath={'/articles'}
            onItemClick={(item) =>
              AppActions.navigate(`/article/?id=${item.id}`)
            }
          />
        </div>
      </Articles>

      <Highlight data-delighter="start:0.2;">
        <div className="content">
          <h2 className="title">
            {landing.custom_layout.hishop_highlight_section.title ||
              'Highlight'}
          </h2>
          <div className="description">
            {landing.custom_layout.hishop_highlight_section.text ||
              'Highlight text'}
          </div>
          <div style={{flex: 1}} />
          <div className="dialog">
            <div className="bubble">
              {landing.custom_layout.hishop_highlight_section.bubble_text ||
                'Bubble text'}
            </div>
            <div className="avatar">
              <img
                src={
                  landing.custom_layout.hishop_highlight_section
                    .bubble_avatar_image || '/images/revicon_512.png'
                }
                alt="avatar"
              />
              <div className="text">
                {landing.custom_layout.hishop_highlight_section
                  .bubble_avatar_name || 'Avatar Name'}
              </div>
            </div>
          </div>
          <div className="link">
            {landing.custom_layout.hishop_highlight_section.ext_link_prefix ||
              '詳情請見'}
            <a
              href={landing.custom_layout.hishop_highlight_section.ext_link_url}
              target="_blank"
              rel="noreferrer">
              {landing.custom_layout.hishop_highlight_section.ext_link_text ||
                '此處'}
            </a>
          </div>
        </div>
      </Highlight>

      <CTA id="cta">
        <div className="content">
          <h2 className="title">
            {landing.custom_layout.hishop_cta_title || 'Contact Us'}
          </h2>
          <ul>
            {(landing.custom_layout.hishop_cta_items || []).map((item, idx) => {
              return <li key={idx}>{item.text}</li>;
            })}
          </ul>
          <button
            className="btn"
            onClick={() => {
              AppActions.navigate(`/schedule-demo`, {loading: 1200});
            }}>
            {landing.custom_layout.hishop_cta_btn_text || 'GO'}
          </button>
        </div>
      </CTA>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: 'Lato', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }

  button {
    display: block;
  }

  .btn {
    border: none;
    border-radius: 0px;
    padding: 10px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 100px;
    transition: all 300ms;
    cursor: pointer;

    &:hover {
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.44);
    }

    &:active {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.36);
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes infinite-spinning {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }
`;

const HeroBanner = styled.section`
  padding: 80px 6vw;
  min-height: 80vh;
  background-image: url('${(props) => props.bgImage}');
  background-repeat: none;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    & > .text {
      flex: 420px 1 1;

      & > .title {
        color: white;
        font-size: 53px;
        text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      }

      & > .subtitle {
        color: white;
        font-size: 24px;
        text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
      }

      & > .btn {
        font-size: 20px;
        margin: 20px 0;
        animation: heartbeat 1.6s infinite;
        backgrounc-color: white;
      }
    }

    & > .image {
      padding: 30px;
      flex: 420px 0 0;

      & > img {
        width: 100%;
      }
    }

    @media screen and (max-width: 800px) {
      & > .text {
        flex: 300px 1 1;
      }

      & > .image {
        flex: 300px 0 0;
      }
    }
  }
`;

const Customers = styled.section`
  overflow: hidden;

  & > .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 5vw 60px 5vw;

    & > .title {
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;

      &.delighter {
        transition: all 300ms ease-out;
        transform: translateX(300px);
        opacity: 0;

        &.started {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }

    & > .items {
      display: grid;
      grid-template-columns: repeat(auto-fit, 320px);
      grid-gap: 3vw;
      justify-content: center;

      & > * {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > figure {
          border: 2px solid #ccc;
          border-radius: 8px;
          overflow: hidden;
          width: 320px;
          height: 240px;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 300ms;
            margin-bottom: 12px;
          }

          & > .on-hover {
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            transition: all 200ms;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:hover > .on-hover {
            height: 100%;
          }
        }

        & .title {
          font-size: 24px;
          margin-bottom: 6px;
        }

        & .btn {
          display: inline-block;
          margin-top: 18px;
        }
      }
    }
  }
`;

const Features = styled.section`
  background-color: #eee;
  padding: 100px 5vw;
  overflow: hidden;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;

    & > h2.title {
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
    }

    & > .items {
      & > * {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        & > img {
          width: 144px;
          height: 144px;
          margin: 20px;
        }

        & > .content {
          margin: 20px;
          flex: 300px 1 1;

          & > .title {
            display: flex;
            font-size: 24px;
            margin-bottom: 10px;
            align-items: center;

            & > .idx {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: orange;
              color: white;
              margin-right: 6px;
            }
          }

          & > .description {
            font-size: 20px;
            color: #666;
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          &.delighter {
            transform: translateX(300px);
          }
        }

        &.delighter {
          transition: all 300ms ease-out;
          transform: translateX(-300px);
          opacity: 0;

          &.started {
            transform: translateX(0px);
            opacity: 1;
          }
        }
      }
    }
  }
`;

const Screenshots = styled.section`
  background-color: #eee;
  padding: 80px 0;

  & > h2.title {
    text-align: center;
    font-size: 40px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 10px;
    justify-content: center;
    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 40px;

    &::before,
    &::after {
      content: '';
      height: 1px;
      background-color: #aaa;
      align-self: center;
    }
  }

  & > .content {
    display: flex;
    z-index: 1;
    position: relative;
    justify-content: center;
  }
`;

const Articles = styled.section`
  padding: 80px 5vw;
  min-height: 300px;

  & > h2.title {
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }

  & > .product-list {
    margin: 0 auto;
    max-width: 800px;
  }

  & > .btn {
    font-size: 20px;
    margin: 0 auto;
  }
`;

const Highlight = styled.section`
  margin-top: -220px;
  padding: 0px 20px 0px 20px;

  &.delighter {
    transition: all 300ms ease-out;
    transform: scale(0.9);
    opacity: 0.9;

    &.delighter.started {
      transform: scale(1);
      opacity: 1;
    }
  }

  & > .content {
    transform: translateY(220px);
    max-width: 800px;
    margin: 0px auto;
    border-radius: 24px;
    background-color: #fff5f5;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.33);
    padding: 60px 30px;
    min-height: 600px;
    display: flex;
    flex-direction: column;

    & > h2.title {
      font-size: 40px;
      margin-bottom: 5px;
    }

    & > .description {
      font-size: 22px;
    }

    & > .dialog {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      & > .bubble {
        flex: 1;
        margin: 0 60px 20px 0;
        background-color: white;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        padding: 20px;
        font-size: 18px;
        transform: translateY(36px);
      }

      & > .avatar {
        width: 80px;
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid white;
          object-fit: cover;
          margin-bottom: 10px;
        }

        & > .text {
          text-align: center;
        }
      }
    }

    & > .link {
      margin-top: 30px;
      font-size: 16px;
      color: #888;
      & > a {
        padding-left: 8px;
        display: inline-block;
        color: black;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`;

const CTA = styled.section`
  background-color: rgb(255, 57, 0, 64%);
  padding: 300px 0 80px 0;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2.title {
      font-size: 40px;
      color: white;
      margin-bottom: 5px;
    }

    & > ul {
      list-style-type: none;
      margin-bottom: 20px;

      & > * {
        font-size: 20px;
        color: #eee;
      }
    }

    & > .btn {
      font-size: 24px;
      margin: 0 auto;
      animation: heartbeat 1.6s infinite;
      background-color: white;
    }
  }
`;
